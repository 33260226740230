import React, { createContext, useContext } from 'react';

type ContextHelpers<ContextValue, Name extends string> = {
  [K in Name as `use${K}`]: () => ContextValue;
} &
  {
    [K in Name as `${K}Provider`]: React.Provider<ContextValue | undefined>;
  };

/**
 * Takes care of the boilerplate in creating contexts.
 *
 * The odd curried function signature is designed to infer the types as much as possible
 * and produce the names of the functions based on the string passed in.
 *
 * @returns The provider and a safe accessor hook that will throw an error if used outside of a provider.
 * @example
 * interface DropdownContextValue {
 *   selectedIdx: number;
 *   dataTestid: 'select-state';
 * }
 * const { useDropdown, DropdownProvider } = createContextHelper<DropdownContextValue>()('Dropdown');
 */
export const createContextHelper = <ContextValue,>() => <Name extends string>(
  name: Name
): ContextHelpers<ContextValue, Name> => {
  const context = createContext<ContextValue | undefined>(undefined);
  context.displayName = name;
  const useContextValue = (): ContextValue => {
    const value = useContext(context);
    if (!value) {
      throw new Error(`use${name} must be used inside a valid ${name}Provider`);
    }
    return value;
  };
  const { Provider } = context;
  return {
    [`use${name}`]: useContextValue,
    [`${name}Provider`]: Provider,
  } as ContextHelpers<ContextValue, Name>;
};
