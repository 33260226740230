import AppActionTypeEnum from './Constants';

const setQuery = (query, queryString) => (dispatch) =>
  dispatch({
    type: AppActionTypeEnum.QUERY_SET,
    query,
    queryString,
  });
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default setQuery;
