import FormSwiftAPIClient from 'FormSwiftAPIClient';
import getConfig from 'next/config';
import UserActionTypeEnum from './Constants';

const { publicRuntimeConfig } = getConfig();
const formSwiftAPIClient = new FormSwiftAPIClient(
  publicRuntimeConfig.API_URL,
  publicRuntimeConfig.INTERNAL_API_URL
);

export const validateUser = (userId, userToken) => async (dispatch) => {
  if (!userId || !userToken) {
    return dispatch({
      type: UserActionTypeEnum.SET_LOGGED_IN_STATUS,
      loggedIn: false,
    });
  }

  try {
    const response = await formSwiftAPIClient.request(
      'POST',
      '/user/validate',
      {
        user_id: userId,
        user_token: userToken,
      }
    );
    const loggedIn = response.data.logged_in;
    return dispatch({
      type: UserActionTypeEnum.SET_LOGGED_IN_STATUS,
      loggedIn,
    });
  } catch (e) {
    return dispatch({
      type: UserActionTypeEnum.SET_LOGGED_IN_STATUS,
      loggedIn: false,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  const response = await fetch(`${publicRuntimeConfig.API_URL}/user/login`, {
    method: 'post',
    body: JSON.stringify({ email, password }),
  });
  const data = await response.json();
  const loggedIn = data.status === 'success';
  return dispatch({
    type: UserActionTypeEnum.SET_LOGGED_IN_STATUS,
    loggedIn,
  });
};

export const logout = () => (dispatch) =>
  dispatch({
    type: UserActionTypeEnum.SET_LOGGED_IN_STATUS,
    loggedIn: false,
  });

export const saveUser = (userId, userToken, email) => (dispatch) =>
  dispatch({
    type: UserActionTypeEnum.SAVE_USER,
    userId,
    userToken,
    email,
  });

export const getUser = () => (dispatch) =>
  dispatch({
    type: UserActionTypeEnum.GET_USER,
  });

export const saveUserInfo = (userInfo) => (dispatch) =>
  dispatch({
    type: UserActionTypeEnum.USER_INFO_SAVE,
    userInfo,
  });
