import isServerSide from 'Utils/isServerSide';

/** Isomorphically gets the hostname */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default function getHostname(ctx) {
  const { req } = ctx;
  let hostname;
  if (isServerSide()) {
    hostname = req.headers.host;
  } else {
    // Client side
    ({ hostname } = window.location);
  }
  return hostname;
}
