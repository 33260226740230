import React from 'react';
import styles from './ResetPasswordSuggestion.module.scss';

export const ResetPasswordSuggestion = (): JSX.Element => (
  <>
    Please try again or{' '}
    <a
      className={styles['reset-password-link']}
      data-testid="reset-password-link"
      href="/forgot-password"
    >
      reset your password
    </a>
    . If you signed in with Google, you can set a password{' '}
    <a
      className={styles['reset-password-link']}
      data-testid="reset-password-link-for-google"
      href="/forgot-password"
    >
      here
    </a>
    .
  </>
);
