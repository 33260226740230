import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { availableFaxNumbers } from './availableFaxNumbers';
import { error } from './error';
import { isSecondaryButtonShown } from './isSecondaryButtonShown';
import { success } from './success';

const ChooseFaxNumberHandler = (state, action) => {
  const combinedReducer = combineReducers({
    availableFaxNumbers,
    error,
    isSecondaryButtonShown,
    success,
  });
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.ChooseFaxNumberHandler };
    default:
      return combinedReducer(state, action);
  }
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default ChooseFaxNumberHandler;
