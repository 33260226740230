import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { changeAddress } from './actions/changeAddress';
import { changeAddress2 } from './actions/changeAddress2';
import { changeCity } from './actions/changeCity';
import { changeCountry } from './actions/changeCountry';
import { changeFirstName } from './actions/changeFirstName';
import { changeLastName } from './actions/changeLastName';
import { changeState } from './actions/changeState';
import { changeZipCode } from './actions/changeZipCode';
import { changeVatNumber } from './actions/changeVatNumber';
import { updateCheckoutFormFields } from './actions/updateCheckoutFormFields';
import { CheckoutState, defaultInitialCheckoutState } from './checkoutState';
import { refreshChargifyJSSecurityToken } from './actions/refreshChargifyJSSecurityToken';
import { subscribe } from './actions/subscribe';
import { changeOrganizationName } from './actions/changeOrganizationName';

export const CheckoutHandler = createSlice({
  extraReducers: (builder) => {
    builder.addCase(updateCheckoutFormFields.pending, (state, action) => {
      state.isPreviewPending = true;
      if (action.meta.arg) {
        state.planId = action.meta.arg.plan;
        state.isTestPurchase =
          action.meta.arg.isTestPurchase || state.isTestPurchase;
        state.documentType = action.meta.arg.documentType;
        state.faxNumber = action.meta.arg.faxNumber;
        state.isFreeWrite = action.meta.arg.isFreeWrite;
      }
      state.error = null;
    });
    builder.addCase(updateCheckoutFormFields.fulfilled, (state, action) => {
      if (action.payload) {
        state.chargify = action.payload.data
          .chargify as CheckoutState['chargify'];
        state.error =
          action.payload.status === 'error' ? action.payload.message : null;
      }
      state.isPreviewPending = false;
    });
    builder.addCase(updateCheckoutFormFields.rejected, (state, action) => {
      state.isPreviewPending = false;
      if (!action.meta.aborted) {
        state.error = action.error.message || null;
      }
    });
    builder.addCase(refreshChargifyJSSecurityToken.pending, (state) => {
      state.isPreviewPending = true;
    });
    builder.addCase(
      refreshChargifyJSSecurityToken.fulfilled,
      (state, action) => {
        state.chargifyJsSecurityToken = action.payload;
        state.isPreviewPending = false;
      }
    );
    builder.addCase(refreshChargifyJSSecurityToken.rejected, (state) => {
      state.chargifyJsSecurityToken = null;
      state.isPreviewPending = false;
    });
    builder.addCase(changeLastName.pending, (state, action) => {
      state.lastName = action.meta.arg;
    });
    builder.addCase(changeFirstName.pending, (state, action) => {
      state.firstName = action.meta.arg;
    });
    builder.addCase(changeAddress.pending, (state, action) => {
      state.address = action.meta.arg;
    });
    builder.addCase(changeAddress2.pending, (state, action) => {
      state.address2 = action.meta.arg;
    });
    builder.addCase(changeCity.pending, (state, action) => {
      state.city = action.meta.arg;
    });
    builder.addCase(changeCountry.pending, (state, action) => {
      state.country = action.meta.arg;
      if (state.country === 'US') {
        state.organization = undefined;
        state.vatNumber = undefined;
      }
    });
    builder.addCase(changeState.pending, (state, action) => {
      state.state = action.meta.arg;
    });
    builder.addCase(changeVatNumber.pending, (state, action) => {
      state.vatNumber = action.meta.arg;
    });
    builder.addCase(changeOrganizationName.pending, (state, action) => {
      state.organization = action.meta.arg;
    });
    builder.addCase(changeZipCode.pending, (state, action) => {
      state.cardZipError = '';
      state.zipCode = action.meta.arg;
    });
    builder.addCase(HYDRATE, (state, action) => {
      const retypedAction = (action as unknown) as {
        payload: { CheckoutHandler: CheckoutState };
      };
      Object.assign(state, retypedAction.payload.CheckoutHandler);
    });
    builder.addCase(subscribe.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(subscribe.rejected, (state, action) => {
      state.isSubmitting = false;
      state.error = action.payload as string;
    });
  },
  initialState: defaultInitialCheckoutState,
  name: 'CheckoutHandler',
  reducers: {
    blurState: (state) => {
      state.showBillingStateError = !state.state && state.country === 'US';
    },
    cardTypeDetected: (state, action: { payload: string }) => {
      state.cardType = action.payload;
    },
    clickedBusinessCheckbox: (state) => {
      if (typeof state.organization === 'undefined') {
        state.isB2B = true;
        state.organization = '';
        state.vatNumber = '';
      } else {
        state.isB2B = false;
        state.organization = undefined;
        state.vatNumber = undefined;
      }
    },
    clickedSubmit: (state) => {
      state.showBillingStateError = !state.state && state.country === 'US';
      state.hasSubmitted = true;
      state.isSubmitting = true;
    },
    clickedTermsCheckbox: (state) => {
      state.termsAccepted = !state.termsAccepted;
    },
    finishedSubmission: (state) => {
      state.isSubmitting = false;
    },
    tokenError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isSubmitting = false;
    },
  },
});
