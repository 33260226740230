const ChooseFaxNumberActionTypeEnum = {
  GET_AVAILABLE_FAX_NUMBERS_FAILURE: 'GET_AVAILABLE_FAX_NUMBERS_FAILURE',
  GET_AVAILABLE_FAX_NUMBERS_HIDE_SECONDARY_BUTTON:
    'GET_AVAILABLE_FAX_NUMBERS_HIDE_SECONDARY_BUTTON',
  GET_AVAILABLE_FAX_NUMBERS_SUCCESS: 'GET_AVAILABLE_FAX_NUMBERS_SUCCESS',
  GET_AVAILABLE_FAX_NUMBERS_RESET: 'GET_AVAILABLE_FAX_NUMBERS_RESET',
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default ChooseFaxNumberActionTypeEnum;
