import { generateQueryString } from 'Utils';
import qs from 'qs';
import { NextRouter } from 'next/router';
import 'formdata-polyfill';

const parseRedirectPath = (redirectURL: string): string => {
  // Take only the path component and redirect to that, otherwise we could
  // unintentionally redirect to a full url set by a hacker
  // setting parser to 'a' tag for easy url parsing,
  // more info here https://gist.github.com/jlong/2428561
  const parser = document.createElement('a');
  parser.href = redirectURL;
  const redirectPath = `${parser.pathname}${parser.search}${parser.hash}`;
  return redirectPath.charAt(0) === '/' ? redirectPath : `/${redirectPath}`;
};

export const getSuccessRedirectUrl = (
  redirectUrl: string,
  params: NextRouter['query']
): string => {
  let redirectPath;
  if (redirectUrl) {
    const parser = document.createElement('a');
    parser.href = redirectUrl;
    const redirectQueryParams = qs.parse(parser.search.substring(1));
    const urlParams = generateQueryString({
      ...params,
      ...redirectQueryParams,
    });
    redirectPath = parseRedirectPath(
      `${parser.pathname}?${urlParams}${parser.hash}`
    );
  } else {
    redirectPath = `/upgrade-pricing?${generateQueryString(params)}`;
  }

  return redirectPath;
};
