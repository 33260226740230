import { createSlice } from '@reduxjs/toolkit';
import { CredentialResponse } from 'GoogleSignIn/types';
import { clickedGoogleSignUpCta } from './actions';

export type GoogleSignUpState = {
  data: CredentialResponse | Record<string, unknown>;
  oneTapTosDialogIsOpen: boolean;
};

export const defaultGoogleSignUpState: GoogleSignUpState = {
  data: {},
  oneTapTosDialogIsOpen: false,
};

const googleSignUpSlice = createSlice({
  initialState: defaultGoogleSignUpState,
  name: 'googleSignIn',
  reducers: {
    clickedCloseOneTapTosDialog(state) {
      state.oneTapTosDialogIsOpen = false;
    },
  },
  // This is a case where alphabetical ordering of keys will make the code harder to read.
  // eslint-disable-next-line sort-keys
  extraReducers: (builder) => {
    builder.addCase(clickedGoogleSignUpCta.fulfilled, (state, action) => {
      if (action.payload.openTosDialog) {
        state.oneTapTosDialogIsOpen = true;
      }
      state.data = action.payload.data;
    });
  },
});

const { clickedCloseOneTapTosDialog } = googleSignUpSlice.actions;
export { clickedCloseOneTapTosDialog };
export const googleSignUpReducer = googleSignUpSlice.reducer;
