import cookie from 'cookie';
import Cookies, { CookieAttributes } from 'js-cookie';
import { NextPageContext } from 'next';
import isServerSide from 'Utils/isServerSide';
import { getCookies } from 'lib/dataFetchers';
import {
  CookieCategorizationsObject,
  DBXConsentCookieObject,
} from 'lib/cookies/dbxConsentCookie';
import * as cookieCategorizationData from '../../public/cookie_categorizations.json';

/**
 * Sets a cookie either server side or client side depending on the context it's being called in. Although the res
 * argument is not required by type, it is required for the cookie to be set server side.
 *
 * @param {string} cookieLabel: the key for the cookie you want to set.
 * @param {string} cookieValue: the value of the cookie you want to set
 * @param {object} ctx: ctx is the Context object provided by Next.js. It has an attribute, ctx.res
 * that represents a https server response object and is necessary to set the cookie server side.
 * @param {object} options: options for setting the cookie, including expiration date. Note, the expires options
 * needs to be set to a Date, not a number, if calling from a typescript file. For example, if you want to set a
 * cookie that expires 5 days from now, set options to { expires: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000) }
 * the daysFromNowToDate util in lib/dateUtils was created to do this conversion for you.
 */

// Both the `cookie` and `js-cookie` library specify possible options types slightly differently. The CompatibleCookieOptions
// type extends the js-cookie options type and overwrites attributes that have type conflicts to a narrower type that is
// is compatible with both libraries.
interface CompatibleCookieOptions extends CookieAttributes {
  expires?: Date | undefined;
  sameSite?: 'strict' | 'lax' | 'none' | undefined;
}

function isomorphicSetCookie(
  cookieLabel: string,
  cookieValue: string,
  ctx: NextPageContext,
  options: CompatibleCookieOptions = {}
): void {
  // As a part of privacy consent, we block cookies from being set if
  // 1. The cookie is not categorized/not defined in cookie_categorizations.json
  // 2. The cookie is in an opted out category for the user
  const cookieCategorizationsObject = cookieCategorizationData as CookieCategorizationsObject;
  const cookieCategory: string | undefined =
    cookieCategorizationsObject[cookieLabel];
  if (!cookieCategory) {
    return;
  }
  const cookies = getCookies(ctx);
  const dbxCookie = cookies['__Secure-dbx_consent'];

  if (dbxCookie) {
    const dbxConsentCookie = JSON.parse(dbxCookie) as DBXConsentCookieObject;
    const consentPreferences = dbxConsentCookie.categories;
    const categoryAllowed = consentPreferences[cookieCategory];

    if (!categoryAllowed) {
      return;
    }
  }

  const optionsWithDefaultPath = { path: '/', secure: true, ...options };

  if (!isServerSide()) {
    Cookies.set(cookieLabel, cookieValue, optionsWithDefaultPath);
  } else if (ctx && ctx.res) {
    // If isomorphicSetCookie runs more than once before the response is sent back from the server there is potential
    // to overwrite existing 'set-cookie' header values because res.setHeader overwrites existing values. Therefore, we
    // check for existing values first and collect them in an array before calling res.setHeader.
    let setCookieValues = [
      cookie.serialize(cookieLabel, cookieValue, optionsWithDefaultPath),
    ];
    const existingHeaderValues = ctx.res.getHeader('set-cookie');
    if (existingHeaderValues instanceof Array) {
      setCookieValues = setCookieValues.concat(existingHeaderValues);
    }
    ctx.res.setHeader('Set-Cookie', setCookieValues);
  } else {
    throw new TypeError(
      'The ctx argument cannot be null or undefined. The ctx object from getInitialProps is expected.'
    );
  }
}

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default isomorphicSetCookie;
