import produce from 'lib/immer';
import { HYDRATE } from 'next-redux-wrapper';
import AppActionTypeEnum from './Constants';

export const initialState = {
  query: {},
  queryString: '',
};

// TODO: Get rid of this file once we get rid of this function
// eslint-disable-next-line regex/invalid
const AppHandler = produce((state, action) => {
  switch (action.type) {
    case HYDRATE:
      // TODO: Get rid of this file once we get rid of this function
      // eslint-disable-next-line regex/invalid
      Object.assign(state, action.payload.AppHandler);
      break;
    case AppActionTypeEnum.QUERY_SET:
      state.query = action.query;
      state.queryString = action.queryString;
      break;
    default:
      break;
  }
}, initialState);

// TODO: Get rid of this file once we get rid of this function
// eslint-disable-next-line regex/invalid
export default AppHandler;
