import { generateQueryString } from 'Utils';
import setQuery from 'App/Actions';

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default function getQuery(ctx) {
  const { query } = ctx;
  const queryString = generateQueryString(query);
  return { query, queryString };
}

/** Isomorphically gets the query string and the parsed dictionary form
 * and loads them into redux.
 */
export async function getQueryAndStoreInRedux(ctx) {
  const { store } = ctx;
  const { query, queryString } = getQuery(ctx);
  await store.dispatch(setQuery(query, queryString));
  return { query, queryString };
}
