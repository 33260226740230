import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import getConfig from 'next/config';
import isServerSide from 'Utils/isServerSide';
import { useRouter } from 'next/router';
import { generateQueryString } from 'Utils';
import { CountryDataProvider } from 'lib/useCountryData';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n/i18n';
import initApollo from './initApollo';

const { publicRuntimeConfig } = getConfig();

/**
 * Installs the Apollo Client on NextPageContext
 * or NextAppContext. Useful if you want to use apolloClient
 * inside getStaticProps, getStaticPaths or getServerSideProps
 * @param {NextPageContext | NextAppContext} ctx
 */
export const initOnContext = (ctx) => {
  // Destructure necessary query params for apollo queries and only pass those
  // eslint-disable-next-line camelcase -- legacy query param not in camelcase
  const { force_download } = ctx?.query ?? {};
  const safeQueryString = generateQueryString({ force_download });
  // Initialize ApolloClient if not already done
  const apolloClient =
    ctx.apolloClient ||
    initApollo(ctx.apolloState || {}, safeQueryString, ctx.req);

  // We send the Apollo Client as a prop to the component to avoid
  // calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  apolloClient.toJSON = () => null;

  // Add apolloClient to NextPageContext & NextAppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  ctx.apolloClient = apolloClient;

  return ctx;
};

// Gets the display name of a JSX component for dev tools
const getComponentDisplayName = (Component) => {
  const displayName = Component.displayName || Component.name || 'Unknown';
  return `withApollo(${displayName})`;
};

const withApollo = ({ ssr = true } = {}) => (Component) => {
  const ApolloWrappedComponent = ({
    apolloClient,
    apolloState,
    ...pageProps
  }) => {
    const { query } = useRouter() ?? {};

    let client;
    if (apolloClient) {
      // Happens on: getDataFromTree & next.js ssr
      client = apolloClient;
    } else {
      // Destructure necessary query params for apollo queries and only pass those
      // eslint-disable-next-line camelcase -- legacy query param not in camelcase
      const { force_download } = query ?? {};
      const safeQueryString = generateQueryString({ force_download });
      // Happens on: next.js csr
      client = initApollo(apolloState, safeQueryString);
    }
    return (
      <ApolloProvider client={client}>
        <Component {...pageProps} />
      </ApolloProvider>
    );
  };

  ApolloWrappedComponent.defaultProps = {
    apolloClient: undefined,
    apolloState: undefined,
  };

  ApolloWrappedComponent.propTypes = {
    // https://www.apollographql.com/docs/react/essentials/get-started/#configuration-options
    apolloClient: PropTypes.shape({}),
    apolloState: PropTypes.shape({
      // Dynamic, depending on query results
      data: PropTypes.shape({}),
    }),
  };

  // Set the correct displayName in development
  if (publicRuntimeConfig.NODE_ENV !== 'production') {
    ApolloWrappedComponent.displayName = getComponentDisplayName(Component);
  }

  if (ssr || Component.getInitialProps) {
    ApolloWrappedComponent.getInitialProps = async (ctx) => {
      const { initialProps } = ctx;
      const { isRedirecting } = initialProps || {};

      // If we are redirecting we can bail early
      if (isRedirecting) {
        return {};
      }

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `Component.getInitialProp`.
      const { apolloClient } = initOnContext(ctx);

      // Run wrapped getInitialProps methods
      let pageProps = {};
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
        // If pageProps is undefined we've thrown a redirect error.
        if (!pageProps) {
          return {};
        }
      }
      const countryIsoAlpha2 =
        ctx.req?.headers['cf-ipcountry'] ||
        (!isServerSide()
          ? localStorage.getItem('countryIsoAlpha2')
          : undefined);

      // Only on the server:
      if (isServerSide()) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps;
        }

        // Only if ssr is enabled
        if (ssr) {
          try {
            // Import `getDataFromTree` dynamically.
            // We don't want to have this in our client bundle.
            const { getDataFromTree } = await import(
              '@apollo/client/react/ssr'
            );

            // Since AppComponents and PageComponents have different context types
            // we need to modify their props a little.
            const props = { ...pageProps, apolloClient };
            // Run all GraphQL queries
            await getDataFromTree(
              <I18nextProvider i18n={i18n}>
                <CountryDataProvider value={{ isoAlpha2: countryIsoAlpha2 }}>
                  <Provider store={ctx.store}>
                    <ApolloProvider client={apolloClient}>
                      <Component {...props} />
                    </ApolloProvider>
                  </Provider>
                </CountryDataProvider>
              </I18nextProvider>
            );
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            // This seems to be the source of a memory leak, see https://github.com/kingstreetlabs/KingStreetLabs/issues/10331
            // console.error('Error while running `getDataFromTree`', error);
          }
        }
      }
      return {
        ...pageProps,
        // Extract query data from the Apollo store
        apolloState: apolloClient.cache.extract(),
        // Provide the client for ssr. As soon as this payload
        // gets JSON.stringified it will remove itself.
        apolloClient: ctx.apolloClient,
      };
    };
  }

  return ApolloWrappedComponent;
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default withApollo;
