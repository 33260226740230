import ChooseFaxNumberActionTypeEnum from '../Constants';

export const DEFAULT_STATE = null;

export const error = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_RESET:
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_SUCCESS:
      return null;
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_FAILURE:
      return action.error;
    default:
      return state;
  }
};
