/** Extracts the user info cookies and returns them camelCased
 *
 * @param {{ [key: string]: string }} cookies
 */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default function getUserInfoFromCookies(cookies) {
  const { accountemail, userToken, userid } = cookies;
  const userId = parseInt(userid, 10);
  const email = accountemail ? decodeURIComponent(accountemail) : '';
  return { email, userId, userToken };
}
