import produce from 'lib/immer';
import { HYDRATE } from 'next-redux-wrapper';
import UploadContainerConstants from './Constants';

const initialState = {
  isImageUploadContainerVisible: false,
  isProgressModalVisible: false,
};

const UploadContainerHandler = produce((state, action) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  switch (action.type) {
    case HYDRATE:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      Object.assign(state, action.payload.UploadContainerHandler);
      break;
    case UploadContainerConstants.PROGRESS_MODAL_HIDE:
      state.isProgressModalVisible = false;
      break;
    case UploadContainerConstants.PROGRESS_MODAL_SHOW:
      state.isProgressModalVisible = true;
      break;
    case UploadContainerConstants.IMAGE_UPLOAD_CONTAINER_HIDE:
      state.isImageUploadContainerVisible = false;
      break;
    case UploadContainerConstants.IMAGE_UPLOAD_CONTAINER_SHOW:
      state.isImageUploadContainerVisible = true;
      break;
    default:
      break;
  }
}, initialState);
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default UploadContainerHandler;
