import cookie from 'cookie';
import { isNil, omitBy } from 'lodash';

export const generateQueryString = (params) => {
  if (!params) {
    return '';
  }

  return Object.keys(omitBy(params, isNil))
    .map(
      (param) =>
        `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`
    )
    .join('&');
};

export const parseInitialProps = (req) => {
  let cookies = {};
  let hostname = '';
  if (req) {
    if (req.headers.cookie) {
      cookies = cookie.parse(req.headers.cookie);
    }
    hostname = req.headers.host;
  } else if (typeof document !== 'undefined') {
    cookies = cookie.parse(document.cookie);
    hostname = window.location.hostname;
  }
  return { cookies, hostname };
};

export const RedirectPages = {
  SIGN_IN: 'sign-in',
  MY_DOCUMENTS: 'my-documents',
  CHECKOUT: 'checkout',
};
