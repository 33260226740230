const TrackingActionTypeEnum = {
  TRACK_EVENT_REQUEST: 'TRACK_EVENT_REQUEST',
  TRACK_EVENT_SUCCESS: 'TRACK_EVENT_SUCCESS',
  TRACK_EVENT_FAILURE: 'TRACK_EVENT_FAILURE',
  TRACK_USER_ADDITIONAL_DATA_REQUEST: 'TRACK_USER_ADDITIONAL_DATA_REQUEST',
  TRACK_USER_ADDITIONAL_DATA_SUCCESS: 'TRACK_USER_ADDITIONAL_DATA_SUCCESS',
  TRACK_USER_ADDITIONAL_DATA_FAILURE: 'TRACK_USER_ADDITIONAL_DATA_FAILURE',
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default TrackingActionTypeEnum;
