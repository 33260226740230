export type CheckoutState = {
  address: string;
  address2: string;
  organization?: string;
  vatNumber?: string;
  cardZipError: string | false;
  cardType: string | null;
  city: string;
  country: string;
  chargifyJsSecurityToken: string | null;
  chargify: {
    // eslint-disable-next-line camelcase
    chargify_js_public_key: string;
    currency?: string;
    documentLabel?: string;
    documentName?: string;
    domain?: string;
    plan?: string;
    planDescription?: string;
    gatewayHandle?: string;
    // eslint-disable-next-line camelcase
    product_price_point_id?: string;
    // eslint-disable-next-line camelcase
    product_handle?: string;
    taxIncluded: boolean;
    afterTrialTax: number;
    afterTrialCharge: number;
    intervalUnit?: string;
    interval?: number;
    trialIntervalUnit?: string;
    trialInterval?: number;
    rebillInterval?: string;
    trialCharge: number;
    trialDuration?: number;
    trialChargeTax: number;
  };
  documentType: string;
  documentName: string;
  faxNumber?: string | null;
  firstName: string;
  hasSubmitted: boolean;
  isB2B: boolean;
  lastName: string;
  planId: string;
  error: string | null;
  showBillingStateError: boolean;
  state: string;
  termsAccepted: boolean;
  zipCode: string;
  isPreviewPending: boolean;
  isTestPurchase: boolean;
  isFreeWrite: boolean;
  isSubmitting: boolean;
};

export const defaultInitialCheckoutState: Readonly<CheckoutState> = {
  address: '',
  address2: '',
  cardType: null,
  cardZipError: false,
  chargify: {
    afterTrialCharge: 0,
    afterTrialTax: 0,
    chargify_js_public_key: '',
    currency: 'USD',
    rebillInterval: '',
    taxIncluded: false,
    trialCharge: 0,
    trialChargeTax: 0,
  },
  chargifyJsSecurityToken: null,
  city: '',
  country: 'US',
  documentName: '',
  documentType: '',
  error: null,
  faxNumber: '',
  firstName: '',
  hasSubmitted: false,
  isB2B: false,
  isFreeWrite: false,
  isPreviewPending: false,
  isSubmitting: false,
  isTestPurchase: false,
  lastName: '',
  planId: '0',
  showBillingStateError: false,
  state: '',
  termsAccepted: false,
  zipCode: '',
};
