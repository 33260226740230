import getCookies from './getCookies';
import getUserInfoFromCookies from './getUserInfoFromCookies';

/**
 * Returns true if the user is signed in based on their cookies.
 * Does not verify the user's token.
 */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default function getIsSignedIn(ctx) {
  const cookies = getCookies(ctx);
  const { userId, userToken } = getUserInfoFromCookies(cookies);
  return !!(userId && userToken);
}
