import { HYDRATE } from 'next-redux-wrapper';
import { createSlice } from '@reduxjs/toolkit';
import { refreshChargifyJSSecurityToken } from 'CheckoutPage/state/actions/refreshChargifyJSSecurityToken';
import { MyAccountConstants, MyAccountModalTypes } from './Constants';
import { showUpdatePaymentInfoModal } from './showUpdatePaymentInfoModal';
import { MyAccountState } from './MyAccountState';
import { updateCard } from './UpdateCard';

export const myAccountInitialState: MyAccountState = {
  cardUpdateError: undefined,
  chargifyJsSecurityToken: null,
  currentModalDisplayed: null,
  isLoadingModalOpen: false,
  isModalOpen: false,
  isUpdateCardBannerVisible: false,
  tokenError: null,
};

export const MyAccountHandler = createSlice({
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action) => {
      const retypedAction = (action as unknown) as {
        payload: { MyAccountHandler: MyAccountState };
      };
      Object.assign(state, retypedAction.payload.MyAccountHandler);
    });
    builder.addCase(MyAccountConstants.MODAL_HIDE, (state) => {
      state.currentModalDisplayed = null;
      state.isModalOpen = false;
      state.tokenError = null;
      state.cardUpdateError = undefined;
      state.chargifyJsSecurityToken = null;
    });
    builder.addCase(MyAccountConstants.PROGRESS_MODAL_SHOW, (state) => {
      state.currentModalDisplayed = MyAccountModalTypes.PROGRESS_MODAL;
      state.isModalOpen = true;
    });
    builder.addCase(
      refreshChargifyJSSecurityToken.fulfilled,
      (state, action) => {
        state.chargifyJsSecurityToken = action.payload;
      }
    );
    builder.addCase(showUpdatePaymentInfoModal.pending, (state) => {
      state.isUpdateCardBannerVisible = false;
      state.currentModalDisplayed =
        MyAccountModalTypes.UPDATE_PAYMENT_INFO_MODAL;
      state.isModalOpen = true;
    });
    builder.addCase(updateCard.pending, (state) => {
      state.chargifyJsSecurityToken = null;
      state.isModalOpen = true;
      state.currentModalDisplayed = MyAccountModalTypes.PROGRESS_MODAL;
      state.tokenError = null;
    });
    builder.addCase(updateCard.fulfilled, (state) => {
      state.currentModalDisplayed = null;
      state.isModalOpen = false;
      state.cardUpdateError = undefined;
      state.chargifyJsSecurityToken = null;
      state.isUpdateCardBannerVisible = true;
    });
    builder.addCase(updateCard.rejected, (state, action) => {
      state.isModalOpen = true;
      state.currentModalDisplayed =
        MyAccountModalTypes.UPDATE_PAYMENT_INFO_MODAL;
      state.cardUpdateError = action.payload?.error;
    });
  },
  initialState: myAccountInitialState,
  name: 'myAccount',
  reducers: {
    closeUpdateCardBanner: (state) => {
      state.isUpdateCardBannerVisible = false;
    },
    tokenError: (state, action) => {
      state.cardUpdateError = action.payload;
    },
  },
});
