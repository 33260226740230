import FormSwiftAPIClient from 'FormSwiftAPIClient';
import getConfig from 'next/config';
import { NextPageContext } from 'next';
import { getUtmData } from 'lib/utmDataUtils';

const { publicRuntimeConfig } = getConfig();
const { API_URL, INTERNAL_API_URL } = publicRuntimeConfig;

const saveUserUtmTracking = async (
  hostname: string,
  pathname: string,
  query: NextPageContext['query'],
  userId: number,
  userToken: string
): Promise<true | undefined> => {
  if (query.utm_source) {
    const utmData = getUtmData(query);
    const fromPage = `${hostname}/${pathname}`;
    const formSwiftAPIClient = new FormSwiftAPIClient(
      API_URL,
      INTERNAL_API_URL
    );
    try {
      await formSwiftAPIClient.request(
        'POST',
        `/user/${userId}/additional-data/save`,
        {
          data: utmData,
          from_page: fromPage,
          user_token: userToken,
        }
      );
    } catch (e) {
      return undefined;
    }
  }
  return true;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default saveUserUtmTracking;
