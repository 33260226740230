import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import { generateQueryString } from 'Utils';

export const EmailExistsMessage = (query: ParsedUrlQuery): JSX.Element => {
  const queryString = generateQueryString(query);
  return (
    <>
      A FormSwift account for that email address already exists. Please
      <a href={`/sign-in${queryString && `?${queryString}`}`}>
        &nbsp;sign in here.&nbsp;
      </a>
    </>
  );
};
