import { isomorphicGetCookie, isomorphicSetCookie } from 'IsomorphicUtils';
import { NextPageContext } from 'next';
import { daysFromNowToDate } from 'lib/dateUtils';
import { getUtmData } from 'lib/utmDataUtils';

const saveUtmParamsToCookie = (
  query: NextPageContext['query'],
  ctx: NextPageContext
): void => {
  if (!isomorphicGetCookie('source', ctx.req) && query.utm_source) {
    const utmJsonString = JSON.stringify(getUtmData(query));
    isomorphicSetCookie('source', utmJsonString, ctx, {
      expires: daysFromNowToDate(5),
    });
  }
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default saveUtmParamsToCookie;
