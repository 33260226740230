import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'locales/en/messages.json';
import enGB from 'locales/en-GB/messages.json';

i18n
  .use(ICU)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // set to true while developing
    // order and from where user language should be detected
    detection: {
      order: ['navigator'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: { useSuspense: false }, // we won't be needing suspense since we're not using a backend to load translations
    resources: {
      en: {
        translation: en,
      },
      'en-GB': {
        translation: enGB,
      },
    },
    supportedLngs: ['en', 'en-US', 'en-GB', 'en-CA', 'en-AU', 'en-IE', 'en-NZ'],
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
