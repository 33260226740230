import { isomorphicGetCookie, isomorphicSetCookie } from 'IsomorphicUtils';

/**
 * Gets the documentType from the query and stores it as a cookie.
 * @param {Object} ctx The Next.js context object.
 */
// eslint-disable-next-line import/no-default-export
export default function getAndSetDocumentType(ctx) {
  let {
    query: { documentType },
  } = ctx;
  if (documentType) {
    isomorphicSetCookie('documentType', documentType, ctx);
  } else {
    const { req } = ctx;
    documentType = isomorphicGetCookie('documentType', req) || 'affidavit';
  }

  return documentType;
}

/**
 * Gets the documentType and stores it in the alternate cookie primaryDocumentType
 * @param {Object} ctx The Next.js context object
 */
export function setPrimaryDocumentType(ctx) {
  const {
    query: { documentType },
  } = ctx;
  if (documentType) {
    isomorphicSetCookie('primaryDocumentType', documentType, ctx);
  }
}
