/* eslint-disable sort-keys */
const isoConsent: Record<
  string,
  { defaultConsent: boolean | undefined; isShownForCountry: boolean }
> = {
  AT: { defaultConsent: undefined, isShownForCountry: true },
  BE: { defaultConsent: undefined, isShownForCountry: true },
  BG: { defaultConsent: undefined, isShownForCountry: true },
  HR: { defaultConsent: undefined, isShownForCountry: true },
  CY: { defaultConsent: undefined, isShownForCountry: true },
  CZ: { defaultConsent: undefined, isShownForCountry: true },
  DK: { defaultConsent: undefined, isShownForCountry: true },
  EE: { defaultConsent: undefined, isShownForCountry: true },
  FI: { defaultConsent: undefined, isShownForCountry: true },
  FR: { defaultConsent: undefined, isShownForCountry: true },
  DE: { defaultConsent: undefined, isShownForCountry: true },
  GR: { defaultConsent: undefined, isShownForCountry: true },
  HU: { defaultConsent: undefined, isShownForCountry: true },
  IE: { defaultConsent: undefined, isShownForCountry: true },
  IS: { defaultConsent: undefined, isShownForCountry: true },
  IT: { defaultConsent: undefined, isShownForCountry: true },
  LV: { defaultConsent: undefined, isShownForCountry: true },
  LI: { defaultConsent: undefined, isShownForCountry: true },
  LT: { defaultConsent: undefined, isShownForCountry: true },
  LU: { defaultConsent: undefined, isShownForCountry: true },
  MT: { defaultConsent: undefined, isShownForCountry: true },
  NL: { defaultConsent: undefined, isShownForCountry: true },
  NO: { defaultConsent: undefined, isShownForCountry: true },
  PL: { defaultConsent: undefined, isShownForCountry: true },
  PT: { defaultConsent: undefined, isShownForCountry: true },
  RO: { defaultConsent: undefined, isShownForCountry: true },
  SK: { defaultConsent: undefined, isShownForCountry: true },
  SI: { defaultConsent: undefined, isShownForCountry: true },
  ES: { defaultConsent: undefined, isShownForCountry: true },
  SE: { defaultConsent: undefined, isShownForCountry: true },
  MX: { defaultConsent: undefined, isShownForCountry: true },
  GB: { defaultConsent: undefined, isShownForCountry: true },
  PE: { defaultConsent: false, isShownForCountry: true },
  CO: { defaultConsent: false, isShownForCountry: true },
  TR: { defaultConsent: false, isShownForCountry: true },
  TW: { defaultConsent: false, isShownForCountry: true },
  VN: { defaultConsent: false, isShownForCountry: true },
  AE: { defaultConsent: false, isShownForCountry: true },
  JP: { defaultConsent: false, isShownForCountry: true },
  KR: { defaultConsent: false, isShownForCountry: true },
  IL: { defaultConsent: false, isShownForCountry: true },
};

/* eslint-enable sort-keys */

export const getEmailConsent = (
  iso: string
): {
  defaultConsent: boolean | undefined;
  isShownForCountry: boolean;
} =>
  iso in isoConsent
    ? isoConsent[iso]
    : { defaultConsent: undefined, isShownForCountry: false };
