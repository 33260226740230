import { setCookieClientSide } from 'lib/cookies';
import { daysFromNowToDate } from 'lib/dateUtils';

export const setRegistrationCookies = (
  email: string,
  userId: string,
  userToken: string,
  isFsNewsLetterAlt: boolean,
  primaryDocumentType = ''
): void => {
  const fwRegex = new RegExp(/[a-z0-9]{32}/gi);
  const isDocCreatedOnFWBuilder = fwRegex.test(primaryDocumentType);
  const COOKIE_DURATION = 60;

  setCookieClientSide('userid', userId, {
    expires: daysFromNowToDate(COOKIE_DURATION),
  });
  setCookieClientSide('userToken', userToken, {
    expires: daysFromNowToDate(COOKIE_DURATION),
  });
  setCookieClientSide('accountemail', encodeURIComponent(email), {
    expires: daysFromNowToDate(COOKIE_DURATION),
  });
  setCookieClientSide('documentCreated', primaryDocumentType, {
    expires: daysFromNowToDate(COOKIE_DURATION),
  });

  if (isDocCreatedOnFWBuilder) {
    setCookieClientSide('documentCreatedOnBuilder', 'freewrite', {
      expires: daysFromNowToDate(COOKIE_DURATION),
    });
  }
  if (isFsNewsLetterAlt) {
    setCookieClientSide('emailsub', encodeURIComponent(email), {
      expires: daysFromNowToDate(COOKIE_DURATION),
    });
  }
};
