import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import isServerSide from 'Utils/isServerSide';
import { GET_USER_EMAIL } from 'FreeWrite/gql/getUserEmail';
import { useUser } from 'FreeWrite/gql/types/useUser';
import { useCallback } from 'react';
import { isomorphicGetCookie } from 'IsomorphicUtils';
import { setCookieClientSide } from 'lib/cookies';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { GA_KEY },
}: { publicRuntimeConfig: { GA_KEY: string } } = getConfig();

/*
  Provides tracking utilities for our 3 event service providers
  1. Google Analytics (GA4)
  2. Mixpanel
  3. Zaius
  Tracking is not guaranteed, so each service's tracking method
  returns a promise that will resolve to true on success or false on failure or after 2 seconds.

  Awaiting tracking:
  useTrack returns a function track which returns a promise when called.

  Both track and useTrack are exported so that
  1. useTrack can be used  within the react lifecycle when convenient
  2. track can be used when it is desirable to invoke tracking
     outside the react lifecycle, as when using Redux middleware to configure a common tracking event
     for many different action invocations.

  Usage:
  const Component = () => {
    const track = useTrack();
    const doSomething = () => {};
    const onClick = async () => {
      await track({action: 'test', category: 'this'});
      doSomething();
    };

    return <button onClick={onClick}/>
  };


  Testing:
  To cut down on testing load, you may use the utilities in useTrack.test-utils
*/

export interface TrackArgs {
  category: string;
  action: string;
}

type UserInfo = {
  email: string;
  userId: number;
};

/* GA */
const trackGoogleAnalytics = ({ action, category }: TrackArgs) =>
  new Promise<boolean>((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve(false);
      }, 2000);
      // Make sure Google Analytics is up and running
      if (typeof window?.gtag !== 'undefined') {
        window.gtag('event', action, {
          event_callback: () => {
            resolve(true);
          },
          event_category: category,
          send_to: GA_KEY,
        });
      } else {
        resolve(false);
      }
    } catch (e) {
      reject(e);
    }
  });
/* END GA */

/* MIXPANEL */
// This is the default. We usually only want to track signed in users. For
// certain special cases, we track signed out users, but double check with
// Product before implementing a new identifier so we don't accidentally run up
// our Mixpanel bill.
const getUserIdentify = ({ email, userId }: UserInfo) => () => {
  const mixpanelDistinctID = '$email';
  window.mixpanel.identify(userId);
  return new Promise<boolean>((resolveSetOnce) => {
    window.mixpanel.people.set_once(mixpanelDistinctID, email, () => {
      resolveSetOnce(true);
    });
  });
};

export type CustomIdentity = { identifier: string; value: string | number };
const getCustomIdentify = ({ identifier, value }: CustomIdentity) => () => {
  // mixpanel.get_distinct_id may not exist if users have custom tracking blockers installed, or if they use
  // firefox on strict settings. Adding this case to fail gracefully and log to sentry.
  if (!window.mixpanel.get_distinct_id) {
    return Promise.reject(
      new Error(
        "Aborting tracking because mixpanel.get_distinct_id does not exist. This may be caused by the user's browser privacy settings."
      )
    );
  }

  const mixpanelId = window.mixpanel.get_distinct_id();
  if (
    !isomorphicGetCookie('alias') ||
    isomorphicGetCookie('alias') !== mixpanelId
  ) {
    setCookieClientSide('alias', mixpanelId);
  }
  window.mixpanel.identify(mixpanelId);
  return new Promise<boolean>((resolveSet) => {
    window.mixpanel.people.set(identifier, value, () => {
      resolveSet(true);
    });
  });
};

const trackAndIncrementMixpanel = ({ action, category }: TrackArgs) => {
  const extensionInstalled = isomorphicGetCookie('extensionInstalled');
  const setExtensionInstalled = new Promise<boolean>((resolveSetExtension) => {
    if (extensionInstalled) {
      window.mixpanel.people.set('extensionInstalled', 'true');
    } else {
      window.mixpanel.people.unset('extensionInstalled');
    }

    resolveSetExtension(true);
  });
  const trackPromise = new Promise<boolean>((resolveTrack) => {
    window.mixpanel.track(
      category,
      {
        action,
        category,
        extensionInstalled: extensionInstalled
          ? !!extensionInstalled
          : undefined,
      },
      {},
      () => {
        void resolveTrack(true);
      }
    );
  });
  const incrementPromise = new Promise<boolean>((resolveIncrement) => {
    window.mixpanel.people.increment(`${category}::num`, 1, () => {
      resolveIncrement(true);
    });
  });
  return Promise.all([setExtensionInstalled, trackPromise, incrementPromise]);
};

const trackMixpanelWithIdentifier = ({
  action,
  category,
  identify,
}: TrackArgs & {
  identify: () => Promise<boolean>;
}) =>
  new Promise<boolean>((resolve, reject) => {
    setTimeout(() => {
      resolve(false);
    }, 2000);
    if (
      typeof window?.mixpanel?.identify === 'function' &&
      typeof window?.mixpanel?.track === 'function' &&
      typeof window?.mixpanel?.people === 'object'
    ) {
      const setPromise = identify();
      const trackAndIncrementPromise = trackAndIncrementMixpanel({
        action,
        category,
      });
      void Promise.all([setPromise, trackAndIncrementPromise])
        .then((values) => resolve(values.every((v) => v)))
        .catch(reject);
    } else {
      resolve(false);
    }
  });

const trackMixpanel = ({ email, userId, ...trackArgs }: TrackArgs & UserInfo) =>
  trackMixpanelWithIdentifier({
    ...trackArgs,
    identify: getUserIdentify({
      email,
      userId,
    }),
  });
/* END MIXPANEL */

/* ZAIUS */
const trackZaius = async ({
  action,
  category,
  email,
  userId,
}: TrackArgs & UserInfo) =>
  new Promise<boolean>((resolve, reject) => {
    setTimeout(() => {
      resolve(false);
    }, 2000);
    if (
      typeof window?.zaius?.entity === 'function' &&
      typeof window?.zaius?.event === 'function'
    ) {
      Promise.all([
        window.zaius
          .entity('customer', {
            customer_id: userId,
            email,
          })
          .catch?.(reject),
        window.zaius
          .event('track', {
            action: category,
            customer_id: userId,
          })
          .catch?.(reject),
        window.zaius
          .event('track', {
            action,
            customer_id: userId,
          })
          .catch?.(reject),
      ])
        .then(() => resolve(true))
        .catch(reject);
    } else {
      resolve(false);
    }
  });
/* END ZAIUS */

// This is a hack
// Mixpanel has length limits on properties, so
// we have to filter out document types from the action
export const getDocumentTypeFilteredAction = (action: string): string => {
  // documentTypes are exactly 32 letters and numbers
  const fwRegex = new RegExp(/[a-z0-9]{32}/gi);
  const [documentType, page] = action.split(' | ');

  if (typeof documentType !== 'undefined' && fwRegex.test(documentType)) {
    // Suppress Free Write documentTypes in reporting
    return `Custom Uploaded Doc | ${page}`;
  }
  return action;
};

export const trackMixpanelCustom = async ({
  identifier,
  value,
  ...trackArgs
}: CustomIdentity & TrackArgs): Promise<boolean> => {
  const { action, category } = trackArgs;
  const updatedAction = getDocumentTypeFilteredAction(action);
  if (window.trackToConsole) {
    // eslint-disable-next-line no-console
    console.log(
      `trackMixpanelCustom() called with ${category},`,
      updatedAction
    );
  }
  try {
    return await trackMixpanelWithIdentifier({
      action: updatedAction,
      category,
      identify: getCustomIdentify({ identifier, value }),
    });
  } catch (e) {
    if (typeof e === 'string') {
      Sentry.captureMessage(e);
    }
    if (e instanceof Error) {
      Sentry.captureMessage(e.message);
    }
    return Promise.resolve(false);
  }
};

export const track = async ({
  action,
  category,
  email,
  userId,
}: TrackArgs & Partial<UserInfo>): Promise<boolean> => {
  if (isServerSide()) {
    return true;
  }
  const updatedAction = getDocumentTypeFilteredAction(action);
  if (window.trackToConsole) {
    // eslint-disable-next-line no-console
    console.log(`track() called with ${category},`, updatedAction);
  }
  const signedOutPromises = [
    trackGoogleAnalytics({
      action: updatedAction,
      category,
    }),
  ];
  const signedInPromises =
    email && userId
      ? [
          trackMixpanel({
            action: updatedAction,
            category,
            email,
            userId,
          }),
          trackZaius({
            action: updatedAction,
            category,
            email,
            userId,
          }),
        ]
      : [];
  const promises = signedInPromises.concat(signedOutPromises);
  const result = await Promise.all(promises).catch(Sentry.captureMessage);
  return Array.isArray(result) && result.every((r) => r);
};

const useTrack = (): (({
  action,
  category,
}: TrackArgs) => Promise<boolean>) => {
  const { data: userData } = useQuery<useUser>(GET_USER_EMAIL);
  const email = userData?.user?.email ?? undefined;
  const userId = userData?.user?.userId ?? undefined;
  const trackingCallback = useCallback(
    (args: TrackArgs) =>
      track({
        ...args,
        email,
        userId,
      }),
    [email, userId]
  );
  return trackingCallback;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default useTrack;
