import { CredentialResponse } from 'GoogleSignIn/types';

export const getWasGsiButtonClicked = (data: CredentialResponse): boolean => {
  const BUTTON_AUTH_TYPES = [
    'btn',
    'btn_confirm',
    'btn_add_session',
    'btn_confirm_add_session',
  ];
  // If you see this ignore please consider refactoring to remove implicit any type as arguments
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return BUTTON_AUTH_TYPES.includes(data.select_by);
};

export const getWasOneTapClicked = (data: CredentialResponse): boolean => {
  return !getWasGsiButtonClicked(data);
};
