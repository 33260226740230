import produce from 'lib/immer';
import TrackingActionTypeEnum from 'Tracking/Constants';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  pendingEvents: [],
  sourceData: {},
};

const TrackingHandler = produce((state, action) => {
  switch (action.type) {
    case HYDRATE:
      Object.assign(state, action.payload.TrackingHandler);
      break;
    case TrackingActionTypeEnum.TRACK_EVENT_REQUEST: {
      state.pendingEvents.push({
        id: action.eventId,
        category: action.category,
        action: action.action,
      });
      break;
    }
    case TrackingActionTypeEnum.TRACK_EVENT_SUCCESS:
    case TrackingActionTypeEnum.TRACK_EVENT_FAILURE: {
      const index = state.pendingEvents.findIndex(
        (e) => e.id === action.eventId
      );
      state.pendingEvents.splice(index, 1);
      break;
    }
    default:
      break;
  }
}, initialState);
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default TrackingHandler;
