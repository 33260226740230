import { gql } from '@apollo/client';

export const GET_USER_EMAIL = gql`
  query useUser {
    user {
      id
      userId
      email
      userId
    }
  }
`;
