import { createSlice } from '@reduxjs/toolkit';
import { getEmailConsent } from 'lib/getEmailConsent';
import { HYDRATE } from 'next-redux-wrapper';

export type EmailMarketingConsentState = {
  countryIsoAlpha2: string | undefined;
  defaultMarketingConsent: boolean | undefined;
  isCheckboxChecked: boolean;
  isConsentToEmailMarketingGiven: boolean | undefined;
  isEmailMarketingConsentOptionShownForCountry: boolean;
  marketingConsentCopy: string;
};

export const defaultEmailMarketingConsentState: EmailMarketingConsentState = {
  countryIsoAlpha2: undefined,
  defaultMarketingConsent: undefined,
  isCheckboxChecked: false,
  isConsentToEmailMarketingGiven: undefined,
  isEmailMarketingConsentOptionShownForCountry: false,
  marketingConsentCopy:
    'I would not like to receive email marketing updates from FormSwift.',
};

export const getInitialEmailMarketingConsentState = (
  countryIsoAlpha2: string | undefined
): EmailMarketingConsentState => {
  if (countryIsoAlpha2) {
    const {
      defaultConsent: defaultMarketingConsent,
      isShownForCountry: isEmailMarketingConsentOptionShownForCountry,
    } = getEmailConsent(countryIsoAlpha2);
    const marketingConsentCopy =
      defaultMarketingConsent !== undefined
        ? 'I would like to receive email marketing updates from FormSwift.'
        : defaultEmailMarketingConsentState.marketingConsentCopy;
    return {
      ...defaultEmailMarketingConsentState,
      countryIsoAlpha2,
      defaultMarketingConsent,
      isConsentToEmailMarketingGiven: defaultMarketingConsent,
      isEmailMarketingConsentOptionShownForCountry,
      marketingConsentCopy,
    };
  }
  return { ...defaultEmailMarketingConsentState };
};

const emailMarketingConsentSlice = createSlice({
  initialState: defaultEmailMarketingConsentState,
  name: 'emailMarketingConsent',
  reducers: {
    changedEmailMarketingConsent(state) {
      state.isConsentToEmailMarketingGiven =
        state.defaultMarketingConsent === undefined &&
        state.isConsentToEmailMarketingGiven === undefined
          ? false
          : !state.isConsentToEmailMarketingGiven;
      state.isCheckboxChecked = !state.isCheckboxChecked;
    },
  },
  // This is a case where alphabetical ordering of keys will make the code harder to read.
  // eslint-disable-next-line sort-keys
  extraReducers: (builder) => {
    // This HYDRATE case is part of a deprecated pattern for hydrating state in our app level store.
    // It will likely be refactored when we get rid of app level state or better integrate it with page
    // level state.
    builder.addCase(HYDRATE, (state, action) => {
      const retypedAction = (action as unknown) as {
        payload: { emailMarketingConsent: EmailMarketingConsentState };
      };
      Object.assign(state, retypedAction.payload.emailMarketingConsent);
    });
  },
});

const { changedEmailMarketingConsent } = emailMarketingConsentSlice.actions;
export { changedEmailMarketingConsent };
export const emailMarketingConsentReducer = emailMarketingConsentSlice.reducer;
