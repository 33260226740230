import ChooseFaxNumberActionTypeEnum from '../Constants';

export const DEFAULT_STATE = false;

export const success = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_SUCCESS:
      return true;
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_RESET:
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_FAILURE:
      return false;
    default:
      return state;
  }
};
