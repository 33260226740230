import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GlobalModalsState = {
  isReactivationModalOpen: boolean;
  isUpdatingSubscriptionModalOpen: boolean;
};

// All modals should start with a false state because non-global modals exist
// Because of that, you want to ensure that no other local modals conflict with
// the global ones
export const defaultGlobalModalsState: GlobalModalsState = {
  isReactivationModalOpen: false,
  isUpdatingSubscriptionModalOpen: false,
};

const globalModalsSlice = createSlice({
  initialState: defaultGlobalModalsState,
  name: 'globalModals',
  reducers: {
    setReactivationModalOpen(
      state,
      action: PayloadAction<GlobalModalsState['isReactivationModalOpen']>
    ) {
      state.isReactivationModalOpen = action.payload;
    },
    setUpdatingSubscriptionModalOpen(state, action: PayloadAction<boolean>) {
      state.isUpdatingSubscriptionModalOpen = action.payload;
    },
  },
});

export const {
  setReactivationModalOpen,
  setUpdatingSubscriptionModalOpen,
} = globalModalsSlice.actions;
export const GlobalModalsReducer = globalModalsSlice.reducer;
