import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const trackGAPageView = () => {
  window.gtag('event', 'page_view', {
    page_location: window.location.pathname,
    page_title: document.title,
    send_to: publicRuntimeConfig.GA_KEY,
  });
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default trackGAPageView;
