import { statusCodeToMessage } from 'ErrorMessage/ErrorMessageUtils';
import * as Sentry from '@sentry/nextjs';
import { setCookieClientSide } from 'lib/cookies';
import Cookies from 'js-cookie';
import { daysFromNowToDate } from 'lib/dateUtils';
import isServerSide from '../Utils/isServerSide';
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default class FormSwiftAPIClient {
  static SITE = 'formswift.com';

  static SITE_NAME = 'Formswift';

  static getBody(payload, jsonStringifyBody) {
    const body = jsonStringifyBody ? JSON.stringify(payload) : payload;
    return body;
  }

  static getQueryString(params) {
    let queryString = '';
    if (!params) {
      return queryString;
    }

    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (queryString.length === 0) {
        queryString += '?';
      } else {
        queryString += '&';
      }
      queryString += `${key}=${value}`;
    });

    return queryString;
  }

  static logException(error) {
    if (!isServerSide() && window.logCapturedExceptionToConsole) {
      console.log(`captured exception: ${error}`);
    }
    Sentry.captureException(error);
  }

  constructor(apiUrl, internalApiUrl) {
    this.fetchRequest = this.fetchRequest.bind(this);
    this.request = this.request.bind(this);
    this.apiUrl = apiUrl;
    this.internalApiUrl = internalApiUrl;
  }

  async fetchRequest(path, options = {}, asRaw = false) {
    const apiUrl = !isServerSide() ? this.apiUrl : this.internalApiUrl;
    try {
      const response = await fetch(`${apiUrl}${path}`, options);
      if (asRaw) {
        return response;
      }

      const json = await response.json();
      if (json.status === 'success') {
        return Promise.resolve(json);
      }

      const error = new Error(json.message);
      if (
        !statusCodeToMessage[json.message] ||
        statusCodeToMessage[json.message].isCritical
      ) {
        FormSwiftAPIClient.logException(error);
      }

      return Promise.reject(error);
    } catch (error) {
      FormSwiftAPIClient.logException(error);
      return Promise.reject(error);
    }
  }

  async login({ email, password, query }) {
    let response;

    try {
      const unclaimedSessionHash = Cookies.get('unclaimed_session_hash');
      const unclaimedSessionKey = Cookies.get('unclaimed_session_key');

      response = await this.request('POST', '/user/login', {
        email,
        password,
        ...query,
        unclaimed_session_hash: unclaimedSessionHash,
        unclaimed_session_key: unclaimedSessionKey,
      });
    } catch (error) {
      return { message: error.message, status: 'fail' };
    }
    // eslint-disable-next-line camelcase
    const { id, user_token } = response.data.user;
    const expires = daysFromNowToDate(60);
    setCookieClientSide('userid', id, { expires });
    setCookieClientSide('userToken', user_token, { expires });
    setCookieClientSide('accountemail', email, { expires });
    Cookies.remove('unclaimed_session_key');
    Cookies.remove('unclaimed_session_hash');
    return { redirectUrl: response.data.redirect_url, status: 'success' };
  }

  sendPasswordResetEmail(payload) {
    const path = '/password-reset';
    const body = FormSwiftAPIClient.getBody(payload, true);
    const options = {
      body,
      method: 'POST',
    };
    return this.fetchRequest(path, options);
  }

  request(
    method,
    path = '',
    payload = {},
    asRaw = false,
    jsonStringifyBody = true,
    requestOptions = {}
  ) {
    if (method === 'GET') {
      const queryString = FormSwiftAPIClient.getQueryString(payload);
      const fullPath = `${path}${queryString}`;

      return this.fetchRequest(fullPath, requestOptions, asRaw);
    }

    const body = FormSwiftAPIClient.getBody(payload, jsonStringifyBody);

    const options = {
      body,
      method: 'POST',
    };

    return this.fetchRequest(path, options, asRaw);
  }
}
