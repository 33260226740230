import { GET_USER_EMAIL } from 'FreeWrite/gql/getUserEmail';
import uuid from 'uuid';
import TrackingActionTypeEnum from 'Tracking/Constants';
import FormSwiftAPIClient from 'FormSwiftAPIClient';
import getConfig from 'next/config';
import isServerSide from 'Utils/isServerSide';
import { initApollo } from 'lib/apollo';

const { publicRuntimeConfig } = getConfig();
const formSwiftAPIClient = new FormSwiftAPIClient(
  publicRuntimeConfig.API_URL,
  publicRuntimeConfig.INTERNAL_API_URL
);

const trackGoogleAnalytics = ({ action, category }) => {
  // Make sure Google Analytics is up and running
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', action, {
      event_category: category,
      send_to: publicRuntimeConfig.GA_KEY,
    });
    return Promise.resolve();
  }
  return Promise.reject();
};

const trackMixpanel = ({ trackingData }) => {
  if (
    typeof window.mixpanel.identify === 'function' &&
    typeof window.mixpanel.track === 'function' &&
    typeof window.mixpanel.people === 'object'
  ) {
    const mixpanelDistinctID = '$email';
    window.mixpanel.identify(trackingData.userId);
    window.mixpanel.people.set_once(mixpanelDistinctID, trackingData.email);
    window.mixpanel.track(trackingData.category, {
      category: trackingData.category,
      action: trackingData.action,
    });
    window.mixpanel.people.increment(`${trackingData.category}::num`);

    return Promise.resolve();
  }

  return Promise.reject();
};

const trackZaius = ({ trackingData }) => {
  if (
    typeof window.zaius.entity === 'function' &&
    typeof window.zaius.event === 'function'
  ) {
    window.zaius.entity('customer', {
      customer_id: trackingData.userId,
      email: trackingData.email,
    });
    window.zaius.event('track', {
      customer_id: trackingData.userId,
      action: trackingData.category,
    });
    window.zaius.event('track', {
      customer_id: trackingData.userId,
      action: trackingData.action,
    });
    return Promise.resolve();
  }
  return Promise.reject();
};

const withFailureTrack = async (
  dispatch,
  eventId,
  trackingFunc,
  trackingArguments
) => {
  let trackingType = TrackingActionTypeEnum.TRACK_EVENT_SUCCESS;
  try {
    await trackingFunc(trackingArguments);
  } catch (e) {
    trackingType = TrackingActionTypeEnum.TRACK_EVENT_FAILURE;
  }
  dispatch({ type: trackingType, eventId });
};

const trackMixpanelZaiusAnalytics = async (
  dispatch,
  eventId,
  category,
  action,
  getState
) => {
  const userState = getState().UserHandler;
  if (userState && userState.loggedIn) {
    const { userId } = userState;
    const apolloClient = initApollo({}, '');
    const result = await apolloClient.query({ query: GET_USER_EMAIL });
    const email = result.data.user.email ?? undefined;

    const trackingData = {
      category,
      action,
      userId,
      email,
    };

    if (typeof window.mixpanel !== 'undefined') {
      withFailureTrack(dispatch, eventId, trackMixpanel, { trackingData });
    }

    if (typeof window.zaius !== 'undefined') {
      withFailureTrack(dispatch, eventId, trackZaius, { trackingData });
    }
  }
};

const trackAnalytics = (category, action, standardAnalytics) => async (
  dispatch,
  getState
) => {
  const eventId = uuid.v4();
  dispatch({
    type: TrackingActionTypeEnum.TRACK_EVENT_REQUEST,
    eventId,
    category,
    action,
  });
  withFailureTrack(dispatch, eventId, trackGoogleAnalytics, {
    category,
    action,
  });
  if (standardAnalytics) {
    trackMixpanelZaiusAnalytics(dispatch, eventId, category, action, getState);
  }
};

// Track User Action Event
/**
 * @deprecated This function should no longer be used. Use `useTrack` and the
 * exported `track` method instead. Consider replacing any uses of `trackEvent`
 * with `track` or `useTrack` if possible.
 */
export const trackEvent = (category, action, standardAnalytics = true) => (
  dispatch
) => {
  if (isServerSide()) {
    return;
  }
  if (window.trackToConsole) {
    console.log(`track() called with ${category}, ${action}`);
  }

  if (!category || !action) {
    Promise.reject('Missing category or action');
    return;
  }

  // documentTypes are exactly 32 letters and numbers
  const fwRegex = new RegExp(/[a-z0-9]{32}/gi);
  const documentType = action.split(' | ')[0];

  let updatedAction = action;
  if (typeof documentType !== 'undefined' && fwRegex.test(documentType)) {
    // Suppress Free Write documentTypes in reporting
    const page = action.split(' | ')[1];
    updatedAction = `Custom Uploaded Doc | ${page}`;
  }

  dispatch(trackAnalytics(category, updatedAction, standardAnalytics));
};

export const trackUserAdditionalData = (fromPage, data) => async (
  dispatch,
  getState
) => {
  const { userId, userToken } = getState().UserHandler;

  if (!data || typeof data !== 'object' || !fromPage || !userId) {
    return;
  }

  dispatch({
    type: TrackingActionTypeEnum.TRACK_USER_ADDITIONAL_DATA_REQUEST,
  });

  try {
    await formSwiftAPIClient.request(
      'POST',
      `/user/${userId}/additional-data/save`,
      {
        data,
        from_page: fromPage,
        user_token: userToken,
      }
    );
    dispatch({
      type: TrackingActionTypeEnum.TRACK_USER_ADDITIONAL_DATA_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: TrackingActionTypeEnum.TRACK_USER_ADDITIONAL_DATA_FAILURE,
    });
  }
};
