import { gql } from '@apollo/client';

/**
 * Fetches documentInfo data via graphql.
 *
 * @param {object} apolloClient: An object representing the Apollo Client which enables
 * graphQL queries.
 *
 * @param {string} documentType: A string representing the document type passed in
 * via the query string.
 *
 * @param {string[]} documentArgs: An array of documentInfo attributes to query for.
 * See the documentInfo graphQL object in the FormSwift API repo as a reference for
 * possible attributes.
 */
const fetchDocumentInfoData = async (
  apolloClient,
  documentType,
  documentArgs
) => {
  try {
    const GET_DOCUMENT_INFO = gql`
    {
      documentInfo(documentType: "${documentType}") {
        ${documentArgs.join(' ')}
      }
    }
    `;
    const results = await apolloClient.query({ query: GET_DOCUMENT_INFO });
    return results?.data?.documentInfo;
  } catch (err) {
    if (process.env.NODE_ENV === 'test') {
      // TODO: Consider fixing linting error when editing file next
      // eslint-disable-next-line no-console
      console.log('Error fetching user data:', err);
    }
    return {};
  }
};

/**
 * Destructures the apolloClient and documentType from context and calls
 * fetchDocumentInfoData. Use only if graphql data is needed
 * inside `getInitialProps`. If a component needs graphql data, it
 * should fetch graphql data directly using `useQuery`.
 *
 * @param {object} ctx: Context object for the component, which in this case is used
 * to access the apolloClient.
 *
 * @param {string[]} documentArgs: An array of documentInfo attributes to query for.
 * See the documentInfo graphQL object in the FormSwift API repo as a reference for
 * possible attributes.
 */

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default async function getDocumentInfoData(ctx, documentArgs) {
  const {
    apolloClient,
    query: { documentType },
  } = ctx;
  const documentInfo = await fetchDocumentInfoData(
    apolloClient,
    documentType,
    documentArgs
  );
  return documentInfo;
}
