import { generateQueryString } from 'Utils';
import appendQueryParam from 'Utils/appendQueryParam';
import { RedirectInfo } from './types';

export const forbiddenQueryParams = ['email', 'password', 'confirm_password'];

const addAllQueryParams = (
  path: string,
  queryObj: { [key: string]: string | number | boolean }
) =>
  Object.keys(queryObj).reduce(
    (pathWithQuery, key) => appendQueryParam(pathWithQuery, key, queryObj[key]),
    path
  );

/**
 * Builds the full redirect url based on a RedirectInfo object.
 * @param page The current page.
 * @param redirectInfo Information on the redirect.
 * @param oldQueryParams The current query parameters.
 * Will be copied to the redirect url unless otherwise specified or overwritten.
 */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default function buildRedirectUrl(
  page: string,
  redirectInfo: RedirectInfo,
  // TODO: Consider fixing linting error next time editing this file
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oldQueryParams: { [key: string]: any }
): string {
  const {
    omitQueryParams = [],
    newQueryParams,
    path,
    redirectBack,
  } = redirectInfo;

  if (!path) {
    return '';
  }

  let query = { ...oldQueryParams };
  [...omitQueryParams, ...forbiddenQueryParams].forEach((key) => {
    delete query[key];
  });

  if (redirectBack) {
    // The sign up/sign in page consumes this query param in order to redirect
    // the user back to their original page after signing in.
    // It expects there to be no leading slash, so we have to slice the first char off the pathname.
    query.redirect_url = `${page.slice(1)}?${generateQueryString(query)}`;
  }
  // When using redirectBack, usually newQueryParams are intended for the immediate redirect (sign in page)
  // rather than the redirect_url. So we're excluding newQueryParams from the redirect_url.
  query = { ...query, ...newQueryParams };

  const redirectPath = addAllQueryParams(path, query);
  return redirectPath;
}
