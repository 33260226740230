export const daysFromNowToDate = (daysFromNow: number): Date => {
  const daysInMilleseconds = Date.now() + daysFromNow * 24 * 60 * 60 * 1000;
  return new Date(daysInMilleseconds);
};

export const localDateFromISO = (ISOstring: string): Date => {
  let UTCISOString = ISOstring;

  // Most of our ISO strings in the DB don't have a Z at the end indicating that they use the UTC time zone
  if (UTCISOString.slice(-1) !== 'Z') {
    UTCISOString = `${UTCISOString}Z`;
  }

  return new Date(UTCISOString);
};

export const localDateStringFromISO = (
  ISOstring: string,
  options?: Intl.DateTimeFormatOptions
): string => {
  const date = localDateFromISO(ISOstring);
  const formattedDate = date.toLocaleDateString('en-US', options);

  return formattedDate;
};

export const hoursUntilISODateString = (isoDateString: string): number => {
  return isoDateString
    ? (Date.parse(isoDateString) - Date.now()) / (1000 * 60 * 60)
    : 0;
};
