import isServerSide from 'Utils/isServerSide';
import { createApolloClient } from './createApolloClient';

let globalApolloClient = null;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
const initApollo = (initialState, queryString, req = null) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (isServerSide()) {
    return createApolloClient(initialState, queryString, req);
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(initialState, queryString);
  }

  return globalApolloClient;
};

// Reset apollo client so it isn't cached between tests
export const resetApollo = () => {
  globalApolloClient = null;
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default initApollo;
