import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkAPI } from 'store/ThunkAPIType';
import { refreshChargifyJSSecurityToken } from './refreshChargifyJSSecurityToken';
import { updateCheckoutFormFields } from './updateCheckoutFormFields';

export const changeCountry = createAsyncThunk<void, string, AppThunkAPI>(
  'changeCountry',
  async (_, thunkAPI) => {
    await thunkAPI.dispatch(updateCheckoutFormFields());
    const chargifyDomain = thunkAPI.getState().CheckoutHandler.chargify
      .domain as string;
    await thunkAPI.dispatch(refreshChargifyJSSecurityToken({ chargifyDomain }));
  }
);
