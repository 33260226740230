import produce from 'lib/immer';
import { HYDRATE } from 'next-redux-wrapper';
import UserActionTypeEnum from './Constants';

export const initialState = {
  loggedIn: false,
  freemium: false,
  userId: null,
  userInfo: {},
  userToken: null,
  email: null,
};

const UserHandler = produce((state, action) => {
  switch (action.type) {
    case HYDRATE:
      Object.assign(state, action.payload.UserHandler);
      break;
    case UserActionTypeEnum.SET_LOGGED_IN_STATUS:
      state.loggedIn = action.loggedIn;
      break;
    case UserActionTypeEnum.SAVE_USER:
      state.userId = action.userId;
      state.userToken = action.userToken;
      state.email = action.email;
      state.loggedIn = true;
      break;
    case UserActionTypeEnum.USER_INFO_SAVE:
      state.userInfo = action.userInfo;
      break;
    default:
      break;
  }
}, initialState);
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default UserHandler;
