import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshChargifyJSSecurityToken } from 'CheckoutPage/state/actions/refreshChargifyJSSecurityToken';
import { initApollo } from 'lib/apollo';
import {
  GetChargifyFieldsDocument,
  GetChargifyFieldsQuery,
} from 'lib/graphql/MyAccount/__generated__/MyAccountQueries.generated';
import getConfig from 'next/config';
import { AppThunkAPI } from 'store/ThunkAPIType';

const {
  publicRuntimeConfig: { DOMAIN_URL },
} = getConfig();

export const showUpdatePaymentInfoModal = createAsyncThunk<
  void,
  undefined,
  AppThunkAPI
>('showUpdatePaymentInfo', async (data, thunkAPI) => {
  const { dispatch } = thunkAPI;
  // Required to prevent weird pop-in of the card update modal when opened.
  const apolloClient = initApollo(
    {},
    ''
  ) as ApolloClient<NormalizedCacheObject>;
  const chargifyFieldsData = await apolloClient.query<GetChargifyFieldsQuery>({
    query: GetChargifyFieldsDocument,
    variables: {
      isUpdatingPaymentInfo: true,
      site: DOMAIN_URL,
    },
  });
  const chargifyDomain = chargifyFieldsData.data.chargifyFields?.domain;
  await dispatch(
    refreshChargifyJSSecurityToken({ chargifyDomain: chargifyDomain as string })
  );
});
