import getCookies from './getCookies';
import { getQueryAndStoreInRedux } from './getQuery';
import getUserInfoFromCookies from './getUserInfoFromCookies';

/** Gets common initial props and updates redux store */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default async function getCommonInitialProps(ctx) {
  const cookies = getCookies(ctx);
  const { query, queryString } = await getQueryAndStoreInRedux(ctx);
  const { email, userId, userToken } = getUserInfoFromCookies(cookies);

  return {
    cookies,
    email,
    query,
    queryString,
    userId,
    userToken,
  };
}
