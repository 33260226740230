import FormSwiftAPIClient from 'FormSwiftAPIClient';
import getConfig from 'next/config';
import { CredentialResponse } from 'GoogleSignIn/types';
import { Dispatch } from 'react';
import { RegisterPayload, UserData } from 'lib/registration/api';
import { NextRouter } from 'next/router';
import { callRegistrationSideEffects } from 'lib/registration/callRegistrationSideEffects';
import { getSuccessRedirectUrl } from 'lib/registration/redirects';

const { publicRuntimeConfig } = getConfig();
const { API_URL, INTERNAL_API_URL } = publicRuntimeConfig;

const handleSuccess = (
  userData: UserData,
  dispatch: Dispatch<unknown>,
  router: NextRouter,
  redirectToPage: string,
  trackRegister: () => void
) => {
  callRegistrationSideEffects(dispatch, router.query, trackRegister, userData);
  // Disabling the no-param-reassign here. Depending on where the user is coming from,
  // there's no good way to predetermine the if there is an error param in the url. Deleting
  // the error param is the easiest way to ensure that the user doesn't accidently get an error
  // message upon success.
  // eslint-disable-next-line no-param-reassign
  delete router.query.error;
  const redirectSuccessPath = getSuccessRedirectUrl(
    redirectToPage,
    router.query
  );
  void router.push(redirectSuccessPath);
};

const handleGoogleRegistrationError = (
  router: NextRouter,
  apiErrorCode: string | null,
  trackError?: (error: string | null) => void
) => {
  const error = apiErrorCode || 'registration-failed-google';
  if (trackError) {
    trackError(error);
  }
  const isWarning = error === 'sign-in-invalid';
  void router.push(
    {
      pathname: router.pathname,
      query: {
        ...router.query,
        ...(isWarning ? { warning: error } : { error }),
      },
    },
    undefined,
    { shallow: true }
  );
};

export const registerWithGoogle = async (
  data: CredentialResponse,
  dispatch: Dispatch<unknown>,
  payload: Omit<RegisterPayload, 'email' | 'password' | 'confirm_password'>,
  redirectToPage = '',
  router: NextRouter,
  trackRegister = () => {},
  trackError?: (error: string | null) => void
  // Disabling the consistent return rule for now. In ticket #12024, the failure case
  // will also return void, at which point we can get rid of this eslint-disable.
): Promise<boolean> => {
  const formSwiftAPIClient = new FormSwiftAPIClient(API_URL, INTERNAL_API_URL);

  try {
    const response = (await formSwiftAPIClient.request(
      'POST',
      `/user/register/third-party/google`,
      { ...data, ...payload },
      true
    )) as Response;
    switch (response.status) {
      case 200: {
        const parsedSuccessfulResponse = (await response.json()) as {
          data: { user: UserData };
        };
        const { user } = parsedSuccessfulResponse.data;
        handleSuccess(user, dispatch, router, redirectToPage, trackRegister);
        return true;
      }
      case 400: {
        const parsedErrorResponse = (await response.json()) as {
          message: string | null;
        };
        handleGoogleRegistrationError(
          router,
          parsedErrorResponse.message,
          trackError
        );
        return false;
      }

      default:
        handleGoogleRegistrationError(router, null, trackError);
        return false;
    }
  } catch (e) {
    handleGoogleRegistrationError(router, null, trackError);
    return false;
  }
};
