import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCheckoutFormFields } from 'CheckoutPage/Utils';
import { AppThunkAPI } from 'store/ThunkAPIType';
import isServerSide from 'Utils/isServerSide';
import { RootState } from 'store/RootState';
import Cookies from 'js-cookie';
import { CheckoutState } from '../checkoutState';

const isSsr = isServerSide();

export const essentialBillingInfoComplete = (state: CheckoutState): boolean => {
  const {
    address,
    city,
    country,
    firstName,
    lastName,
    state: billingState,
    zipCode,
  } = state;
  const billingStateRequired =
    country === 'US' || country === 'CA' || country === 'AU';
  return !!(
    address &&
    city &&
    country &&
    firstName &&
    lastName &&
    (billingState || !billingStateRequired) &&
    zipCode
  );
};

export const updateCheckoutFormFields = createAsyncThunk<
  Awaited<ReturnType<typeof getCheckoutFormFields>> | undefined,
  Parameters<typeof getCheckoutFormFields>[0] | void,
  AppThunkAPI
>('updateCheckoutFormFields', async (data, thunkAPI) => {
  const state: RootState = thunkAPI.getState();

  const {
    address,
    address2,
    city,
    country,
    documentType,
    faxNumber,
    firstName,
    isFreeWrite,
    isTestPurchase,
    lastName,
    organization,
    planId,
    state: billingState,
    vatNumber,
    zipCode,
  } = state.CheckoutHandler;

  const requestData = {
    address: encodeURIComponent(address),
    address2: encodeURIComponent(address2),
    city: encodeURIComponent(city),
    country,
    documentType,
    faxNumber,
    firstName: encodeURIComponent(firstName),
    isFreeWrite,
    isTestPurchase,
    lastName: encodeURIComponent(lastName),
    organization: encodeURIComponent(organization ?? ''),
    plan: planId || '0',
    state: encodeURIComponent(billingState),
    userId: parseInt(isSsr ? '' : Cookies.get('userid') ?? '', 10),
    userToken: isSsr ? '' : Cookies.get('userToken') ?? '',
    vatNumber: encodeURIComponent(vatNumber ?? ''),
    zipCode: encodeURIComponent(zipCode),
    ...data,
  };

  const result = await getCheckoutFormFields(requestData);
  return result;
});
