// In order to use abstract graphql types like unions and interfaces
// in fragments, we need to tell Apollo the relationships between types.
// See: https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
// Fortunately we can read them from schema.json
import schema from 'lib/graphql/schema.json';

function getPossibleTypes(): { [key: string]: string[] } {
  const result: { [key: string]: string[] } = {};
  // eslint-disable-next-line no-underscore-dangle
  schema.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      result[supertype.name] = supertype.possibleTypes.map(
        (subtype) => subtype.name
      );
    }
  });
  return result;
}

// We assume here that schema.json does not change without a rebuild
export const possibleTypes = getPossibleTypes();
