import Cookies, { CookieAttributes } from 'js-cookie';
import * as cookieCategorizationData from '../../public/cookie_categorizations.json';
import {
  CookieCategorizationsObject,
  DBXConsentCookieObject,
} from './dbxConsentCookie';

function setCookieClientSide(
  cookieLabel: string,
  cookieValue: string,
  options: CookieAttributes = {}
): void {
  // As a part of privacy consent, we block cookies from being set if
  // 1. The cookie is not categorized/not defined in cookie_categorizations.json
  // 2. The cookie is in an opted out category for the user
  const cookieCategorizationsObject = cookieCategorizationData as CookieCategorizationsObject;
  const cookieCategory: string | undefined =
    cookieCategorizationsObject[cookieLabel];
  if (!cookieCategory) {
    return;
  }
  const dbxCookie = Cookies.get('__Secure-dbx_consent');

  if (dbxCookie) {
    const dbxConsentCookie = JSON.parse(dbxCookie) as DBXConsentCookieObject;
    const consentPreferences = dbxConsentCookie.categories;
    const categoryAllowed = consentPreferences[cookieCategory];

    if (!categoryAllowed) {
      return;
    }
  }

  const optionsWithDefaultPath = { path: '/', secure: true, ...options };
  Cookies.set(cookieLabel, cookieValue, optionsWithDefaultPath);
}
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default setCookieClientSide;
