import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import getConfig from 'next/config';
import { isomorphicGetCookie, isomorphicBase64Encode } from 'IsomorphicUtils';
import isServerSide from 'Utils/isServerSide';
import { possibleTypes } from 'lib/apollo/getPossibleTypes';
import handleGraphQLError from './handleGraphQLError';

const { publicRuntimeConfig } = getConfig();

/**
 * Composes link for ApolloClient. Handles configuring fetch client,
 * isomorphic behavior, and error logging.
 * @param  {string} [queryString='']
 * @param  {string} [userToken=undefined]
 * @param  {string} [userid=undefined]
 * @param  {string} [cloudflareToken=undefined]
 */
const getComposedLink = (
  queryString,
  userid,
  userToken,
  cloudflareToken,
  optimizelyUserId,
  optimizelyOptOut
) => {
  const fetchLink = createHttpLink({ fetch });

  const isomorphicLink = setContext((_, { headers }) => {
    const { API_URL, INTERNAL_API_URL } = publicRuntimeConfig;
    const apiUrl = !isServerSide() ? API_URL : INTERNAL_API_URL;
    const graphQLEndpoint = `${apiUrl}/formswift/graphql${
      queryString ? '?' : ''
    }${queryString}`;

    let apolloHeaders = {
      ...headers,
      Authorization: `Basic ${isomorphicBase64Encode(
        `${userid}:${userToken}`
      )}`,
      'X-Optimizely-Opt-Out': optimizelyOptOut || null,
      'X-Optimizely-User-Id': optimizelyUserId,
    };

    if (cloudflareToken) {
      apolloHeaders = {
        ...apolloHeaders,
        'Cloudflare-token': cloudflareToken,
      };
    }

    return {
      headers: apolloHeaders,
      uri: graphQLEndpoint,
    };
  });

  const errorLink = onError((error) => {
    handleGraphQLError(error);
  });

  return isomorphicLink.concat(errorLink).concat(fetchLink);
};

// Only attempt to connect to dev tools if we have credentials.
export const connectToDevTools = (userid, userToken) => !!(userid && userToken);

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 * @param  {Object} [req=null]
 * @param  {string} [queryString='']
 */
export const createApolloClient = (
  initialState = {},
  queryString = '',
  req = null
) => {
  const userid = isomorphicGetCookie('userid', req);
  const userToken = isomorphicGetCookie('userToken', req);
  const cloudflareToken = isomorphicGetCookie('cloudflare_token', req);
  const optimizelyUserId = isomorphicGetCookie('optimizelyUserId', req);
  const optimizelyOptOut = isomorphicGetCookie('optimizelyOptOut', req);
  return new ApolloClient({
    cache: new InMemoryCache({ possibleTypes }).restore(initialState),
    connectToDevTools: connectToDevTools(userid, userToken),
    link: getComposedLink(
      queryString,
      userid,
      userToken,
      cloudflareToken,
      optimizelyUserId,
      optimizelyOptOut
    ),
    ssrMode: isServerSide(), // Disables forceFetch on the server (so queries are only run once)
  });
};
