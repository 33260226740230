export const utmKeys = [
  'nonintentid',
  'u_aceid',
  'u_adgroup',
  'u_adposition',
  'u_country',
  'u_device',
  'u_landingpage',
  'u_matchtype',
  'u_network',
  'u_placement',
  'u_product',
  'u_producttype',
  'u_sitelinkid',
  'utm_advariant',
  'utm_campaign',
  'utm_campaignid',
  'utm_content',
  'utm_device',
  'utm_medium',
  'utm_placement',
  'utm_source',
  'utm_term',
] as const;
