import cookie from 'cookie';
import { NextPageContext } from 'next';
import isServerSide from 'Utils/isServerSide';

/**
 * Isomorphically gets the cookies and parses them into a dictionary.
 * Can be called with no arguments on client-side only.
 */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default function getCookies(
  ctx: Pick<NextPageContext, 'req'> = {}
): { [key: string]: string } {
  const { req } = ctx;
  return cookie.parse(
    (isServerSide() ? req?.headers.cookie : document.cookie) ?? ''
  );
}
