// This file configures the initialization of Sentry on the browser.
// The config added here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

Sentry.init({
  dsn: `https://${publicRuntimeConfig.SENTRY_KEY}@o138645.ingest.sentry.io/1219345`,
  environment: publicRuntimeConfig.APP_ENV,
  // Sentry docs recommend to set the release value here even though the setup wizard says not to
  // We should remove this once sourcemaps are enabled
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/releases/#bind-the-version
  release: `${publicRuntimeConfig.REVISION}`,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
});
