import { GoogleSignInWithOneTapProps } from 'GoogleSignIn/types';
import useTrack from 'lib/tracking/useTrack';

export const googleOneTapPromptNotificationHandler = (
  // Currently the action for all events is same. Update this param if needed.
  oneTapTrackingAction: GoogleSignInWithOneTapProps['oneTapTrackingAction'] = '',
  track: ReturnType<typeof useTrack>
): ((notification: google.accounts.id.PromptMomentNotification) => void) => {
  return (moment: google.accounts.id.PromptMomentNotification) => {
    /**
     * When user clicks continue for any account, Google will verify the account and close the popup.
     * Then a dismiss moment will be triggered and w/ reason `credential_returned`.
     * Dismissed moment gets triggered also for "cancel_called" | "flow_restarted".
     */
    const trackOneTapContinued =
      moment.isDismissedMoment() &&
      moment.getDismissedReason() === 'credential_returned' &&
      oneTapTrackingAction;
    if (trackOneTapContinued) {
      void track({
        action: oneTapTrackingAction,
        category: 'Continue | One Tap',
      });
    }
  };
};
