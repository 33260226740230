import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkAPI } from 'store/ThunkAPIType';
import { updateFormFields } from './dispatchUpdateFormFields';
import { essentialBillingInfoComplete } from './updateCheckoutFormFields';

export const changeAddress2 = createAsyncThunk<void, string, AppThunkAPI>(
  'changeAddress2',
  (_, thunkAPI) => {
    const state = thunkAPI.getState();
    if (essentialBillingInfoComplete(state.CheckoutHandler)) {
      void updateFormFields(thunkAPI.dispatch);
    }
  }
);
