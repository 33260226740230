import cookie from 'cookie';
import Router from 'next/router';
import Cookies from 'js-cookie';
import isServerSide from '../Utils/isServerSide';

export { default as isomorphicSetCookie } from './isomorphicSetCookie';

export const isomorphicRedirect = (redirectUrl, res) => {
  if (!isServerSide()) {
    Router.push(redirectUrl);
  } else {
    res.writeHead(302, { Location: redirectUrl });
    res.end();
  }
};

export const isomorphicGetCookie = (cookieLabel, req = {}) => {
  if (req && Object.keys(req).length !== 0) {
    const cookies = cookie.parse(req.headers.cookie || '');
    return cookies[cookieLabel];
  }
  return Cookies.get(cookieLabel);
};

export const isomorphicBase64Encode = (data) => {
  if (!isServerSide()) {
    return btoa(data);
  }
  return Buffer.from(data).toString('base64');
};
