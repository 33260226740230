import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkAPI } from 'store/ThunkAPIType';
import { updateFormFields } from './dispatchUpdateFormFields';

export const changeVatNumber = createAsyncThunk<void, string, AppThunkAPI>(
  'changeVatNumber',
  (_, thunkAPI) => {
    updateFormFields(thunkAPI.dispatch);
  }
);
