import { ErrorResponse } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { OperationDefinitionNode } from 'graphql';

const handleGraphQLError = ({
  graphQLErrors,
  networkError,
  ...ctx
}: ErrorResponse): void => {
  // Only report GraphQL errors to sentry if we encounter a network error.
  // Likely network errors include malformed requests or errors within our api.
  if (!networkError) return;
  Sentry.captureMessage(`[Network error]: ${networkError.message}`);
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message } = error;
      Sentry.withScope((scope) => {
        const definition = ctx.operation.query
          .definitions[0] as OperationDefinitionNode;
        scope.setTag('operationType', definition.operation);
        scope.setTag('operationName', ctx.operation.operationName);
        Sentry.captureMessage(`[GraphQL error]: ${message}`);
      });
    });
  }
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default handleGraphQLError;
