import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkAPI } from 'store/ThunkAPIType';
import { updateFormFields } from './dispatchUpdateFormFields';

export const changeOrganizationName = createAsyncThunk<
  void,
  string,
  AppThunkAPI
>('changeOrganizationName', (_, thunkAPI) => {
  updateFormFields(thunkAPI.dispatch);
});
