import { saveUserInfo } from 'User/Actions';
import { gql } from '@apollo/client';

const fetchUserData = async (apolloClient, userArgs) => {
  try {
    const GET_USER_DATA = gql`
    query GetUserData {
      user {
        id
        ${userArgs.join(' ')}
      }
    }
    `;
    const result = await apolloClient.query({
      query: GET_USER_DATA,
    });
    return result.data.user;
  } catch (err) {
    if (process.env.NODE_ENV === 'test') {
      // TODO: Consider fixing linting error when editing file next
      // eslint-disable-next-line no-console
      console.log('Error fetching user data:', err);
    }
  }
  return {};
};

/**
 * Fetches user info via graphql. Use only if graphql data is needed
 * inside `getInitialProps`. If a component needs graphql data, it
 * should fetch graphql data directly using `useQuery`.
 *
 * @param {string[]} userArgs: An array of user attributes to query for
 */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default async function getUserData(ctx, userArgs) {
  const { apolloClient } = ctx;
  const user = await fetchUserData(apolloClient, userArgs);
  // Extract graphql type name
  const { __typename, id, ...rest } = user || {};
  return rest;
}

/**
 * Populates the redux store with user data during getInitialProps.
 *
 * Intended usage:
 *   storeUserDataInRedux(ctx, await getUserData(['funnel', 'hasPaidAccess']))
 * Replaces the old hoc version:
 *   withUser(['funnel', 'hasPaidAccess'])
 *
 * @deprecated This function gives components access to graphql through redux.
 * However, components should fetch graphql directly through Apollo `useQuery`
 * except Layouts, which can't use `useQuery` until kingstreetlabs/KingStreetLabs#7424 goes out.
 *
 * @param {string[]} userArgs: An array of user attributes to query for
 */
export function storeUserDataInRedux(ctx, userData = {}) {
  const {
    store: { dispatch },
  } = ctx;
  dispatch(saveUserInfo(userData));
}
