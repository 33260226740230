import ChooseFaxNumberActionTypeEnum from '../Constants';

export const DEFAULT_STATE = false;

export const isSecondaryButtonShown = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_FAILURE:
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_SUCCESS:
      return true;
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_HIDE_SECONDARY_BUTTON:
      return false;
    default:
      return state;
  }
};
