import Cookies from 'js-cookie';
import cookieCategorizationData from '../../public/cookie_categorizations.json';

type CategoriesObject = {
  [key: string]: boolean;
};

const getOptOutCategories = (categories: CategoriesObject): Array<string> => {
  const optOutCategories: Array<string> = [];
  Object.entries(categories).forEach(([category, consent]) => {
    if (consent === false) {
      optOutCategories.push(category);
    }
  });
  return optOutCategories;
};

export const expireOptOutCookies = (categories: CategoriesObject): void => {
  const optOutCategories = getOptOutCategories(categories);
  Object.entries(cookieCategorizationData).forEach(([cookie, category]) => {
    if (optOutCategories.includes(category)) {
      Cookies.remove(cookie);
      window.localStorage.removeItem(cookie);
      window.sessionStorage.removeItem(cookie);
    }
  });
};
