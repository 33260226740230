import { Dispatch } from 'react';
import { trackUserAdditionalData } from 'Tracking/Actions';
import { isomorphicGetCookie } from 'IsomorphicUtils';

export const trackUserSource = (fromPage: string) => (
  dispatch: Dispatch<unknown>
): void => {
  let utmSource;
  let sourceData;
  try {
    const sourceCookie = isomorphicGetCookie('source') || '';
    // We disable camelcase because we're dealing with several variables that
    // need to get sent to the api in snake case format.
    // eslint-disable-next-line camelcase
    sourceData = JSON.parse(sourceCookie) as { utm_source: string };
    utmSource = sourceData.utm_source;
  } catch (e) {
    return;
  }
  if (!utmSource) {
    return;
  }
  dispatch(trackUserAdditionalData(fromPage, sourceData));
};
