import { NextPageContext } from 'next';
import { utmKeys } from './constants';
import { UtmData } from './types';

const getUtmData = (query: NextPageContext['query']): object => {
  const utmData: UtmData = {};
  for (let i = 0; i < utmKeys.length; i += 1) {
    if (query[utmKeys[i]]) {
      utmData[utmKeys[i]] = query[utmKeys[i]];
    }
  }

  return utmData;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default getUtmData;
