import { createAsyncThunk } from '@reduxjs/toolkit';
import { isomorphicGetCookie } from 'IsomorphicUtils';
import { AppThunkAPI } from 'store/ThunkAPIType';
import FormSwiftAPIClient from 'FormSwiftAPIClient';
import getConfig from 'next/config';
import isServerSide from 'Utils/isServerSide';

const {
  publicRuntimeConfig: { API_URL, INTERNAL_API_URL },
} = getConfig();
const apiClient = new FormSwiftAPIClient(API_URL, INTERNAL_API_URL);

export const refreshChargifyJSSecurityToken = createAsyncThunk<
  Awaited<string | null>,
  {
    chargifyDomain: string;
    userId?: number;
    userToken?: string;
  },
  AppThunkAPI
>('refreshChargifyJSSecurityToken', async (data) => {
  const userId = data?.userId ? data.userId : isomorphicGetCookie('userid');
  const userToken = data?.userToken
    ? data.userToken
    : isomorphicGetCookie('userToken');
  const { chargifyDomain } = data;
  try {
    const response = await apiClient.request('POST', '/chargify_js_token', {
      domain: chargifyDomain,
      user_id: userId,
      user_token: userToken,
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response.data.chargify_js_token as string;
  } catch (e) {
    if (!isServerSide()) {
      // eslint-disable-next-line no-alert
      window.alert('Something went wrong. Please refresh the page');
    }
  }
  return null;
});
