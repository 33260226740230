import { throttle } from 'lodash';
import { AppDispatch } from 'store/appDispatch';
import isServerSide from 'Utils/isServerSide';
import { updateCheckoutFormFields } from './updateCheckoutFormFields';

const dispatchUpdateFormFields = (dispatch: AppDispatch): void => {
  void dispatch(updateCheckoutFormFields());
};

// For client side use only
// Very important that we don't throttle this on the server side
export const updateFormFields = isServerSide()
  ? dispatchUpdateFormFields
  : throttle(dispatchUpdateFormFields, 1000, {
      leading: false,
      trailing: true,
    });
