import * as Types from '../../schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserDataQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserDataQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    userId?: number | null;
  } | null;
};

export const UserDataDocument = gql`
  query userData {
    user {
      id
      email
      userId
    }
  }
`;

/**
 * __useUserDataQuery__
 *
 * To run a query within a React component, call `useUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDataQuery(
  baseOptions?: Apollo.QueryHookOptions<UserDataQuery, UserDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserDataQuery, UserDataQueryVariables>(
    UserDataDocument,
    options
  );
}
export function useUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserDataQuery,
    UserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserDataQuery, UserDataQueryVariables>(
    UserDataDocument,
    options
  );
}
export type UserDataQueryHookResult = ReturnType<typeof useUserDataQuery>;
export type UserDataLazyQueryHookResult = ReturnType<
  typeof useUserDataLazyQuery
>;
export type UserDataQueryResult = Apollo.QueryResult<
  UserDataQuery,
  UserDataQueryVariables
>;
