/** Appends a query param to a url, prefixing with ? or & as appropriate */
const appendQueryParam = (
  url: string,
  key: string,
  value: string | number | boolean
): string => {
  let newUrl = url;
  const queryParam = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  if (url.includes('?')) {
    newUrl += `&${queryParam}`;
  } else {
    newUrl += `?${queryParam}`;
  }
  return newUrl;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default appendQueryParam;
