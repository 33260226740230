import { createAsyncThunk } from '@reduxjs/toolkit';
import { CredentialResponse } from 'GoogleSignIn/types';
import { registerWithGoogle } from 'GoogleSignIn/api/registerWithGoogle';
import { RegisterPayload } from 'lib/registration/api';
import { NextRouter } from 'next/router';
import { EmailMarketingConsentState } from 'NewAccountForm/newAccountFormState';
import { getWasGsiButtonClicked } from 'GoogleSignIn/determineGsiFlow';
import { checkNoAccountExists } from 'GoogleSignIn/api/checkNoAccountExists';

export const clickedGoogleSignUpCta = createAsyncThunk<
  { data: CredentialResponse; openTosDialog?: boolean },
  {
    data: CredentialResponse;
    defaultPayload: Omit<
      RegisterPayload,
      'email' | 'password' | 'confirm_password'
    >;
    redirectToPage: string;
    router: NextRouter;
    trackRegister: () => void;
  },
  {
    state: { emailMarketingConsent: EmailMarketingConsentState };
  }
>('clickedGoogleSignUpCta', async (payload, thunkAPI) => {
  const {
    data,
    defaultPayload,
    redirectToPage,
    router,
    trackRegister,
  } = payload;
  const {
    isConsentToEmailMarketingGiven,
  } = thunkAPI.getState().emailMarketingConsent;

  if (getWasGsiButtonClicked(data)) {
    void registerWithGoogle(
      data,
      thunkAPI.dispatch,
      {
        ...defaultPayload,
        email_marketing_consent: isConsentToEmailMarketingGiven,
      },
      redirectToPage,
      router,
      trackRegister
    );
  } else {
    const hasNoExistingAccount = await checkNoAccountExists(data, router);
    if (hasNoExistingAccount) {
      return { data, openTosDialog: true };
    }
  }

  return { data };
});
