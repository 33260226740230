import ChooseFaxNumberActionTypeEnum from '../Constants';

export const DEFAULT_STATE = [];

export const availableFaxNumbers = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_SUCCESS:
      return action.availableFaxNumbers;
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_RESET:
    case ChooseFaxNumberActionTypeEnum.GET_AVAILABLE_FAX_NUMBERS_FAILURE:
      return [];
    default:
      return state;
  }
};
